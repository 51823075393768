@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .card {
        background-color: theme('colors.white');
        border-radius: theme('borderRadius.lg');
        box-shadow: theme('boxShadow.md');
    }

    input[type="number"]::-webkit-outer-spin-button,
    input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    }

    input[type="number"] {
    -moz-appearance: textfield; /* Firefox */
    }
}